import { Component, OnInit, AfterViewInit, Input, SimpleChanges } from '@angular/core';
import Swal from 'sweetalert2';
import { AppService } from '../app.service';
import { ApiService } from '../shared/api.service';
import { TypeLabel } from '../shared/types';
import { LeadUpdateService } from 'src/app/services/lead-update.service';

@Component({
  selector: 'app-contact-cart',
  templateUrl: './contact-cart.component.html',
  styleUrls: ['./contact-cart.component.scss']
})
export class ContactCartComponent implements OnInit, AfterViewInit {

  isEditTitle = false;
  title = 0;
  color = '#d35bd8';
  @Input() card: any;
  @Input() createBox: any;
  @Input() dropFunction: any;
  @Input() dragFunction: any;
  @Input() showMainView: any;
  @Input() columnLength: number = 0;
  // @Input() updatePosition: Function  = new Function;
  @Input() updatePosition: any;
  @Input() newData:any = {};
  conversations:any=[];
  cardIndex:any=null;
  typeLabel: TypeLabel = {0: '', 1: 'text', 2: 'imagen', 3: 'video', 4: 'archivo', 5: 'audio'};
  constructor(public app: AppService, private api: ApiService,
    private leadUpdateService: LeadUpdateService) { }
  
  ngOnInit(): void {
    this.title = this.card.grouping.name;
    this.color = this.card.grouping.color || '#d35bd8';
    this.conversations = this.card.conversations;
    if(this.cardIndex==null)
    this.cardIndex = this.app.cards.findIndex(card => card.grouping.id === this.card.grouping.id);
    this.calculateGroupLeads();
    this.leadUpdateService.leadUpdated$.subscribe(() => {
      this.calculateGroupLeads();
    });
  }

  calculateGroupLeads() {
    let totalAmount = 0;  
    this.conversations.forEach((conversation: any) => {
      totalAmount += conversation.amount || 0;
    });
    this.card.grouping.groupAmount = totalAmount;
  }
  

  ngAfterViewInit(): void {
  }


  editTitle() {
    this.app.allowGroupUpdate = false;
    this.isEditTitle = true;
  }
  cancelEditTitle() {
    this.app.allowGroupUpdate = true;

    this.isEditTitle = false;
  }
  saveTitle() {
    this.card.grouping.name = this.title;
    this.api.sendPostRequest({action: 'Grouping/updateName', params: {id: this.card.grouping.id, name: this.title}}).subscribe();
    this.app.allowGroupUpdate = true;
    this.isEditTitle = false;
  }

  deleteGroup() {
    this.app.allowGroupUpdate = false;
    Swal.fire({
      title: 'Eliminar columna : ' + this.card.grouping.name,
      text: '¿Estás seguro?',
      icon: 'warning',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.api.sendPostRequest({action: 'Grouping/delete', params: {id: this.card.grouping.id}}).subscribe(res => {
          const index = this.app.cards.findIndex(card => card.grouping.id === this.card.grouping.id);
          if (index !== -1) {
            this.app.cards.splice(index, 1);
            this.app.cards = this.app.cards.sort((p, n) => p.grouping.position - n.grouping.position);
            this.app.cards.forEach((card, index) => (card.grouping.position = index+1));
          }
        })
      }
      this.app.allowGroupUpdate = true;
    });    
  }

  updateColor() {
    this.api.sendPostRequest({action: 'Grouping/updateStyle', params: {id: this.card.grouping.id, color: this.color}}).subscribe();
  }

}