import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss']
})
export class CreateGroupComponent implements OnInit {
  error: string = '';
  groupBlock: FormGroup = new FormGroup({});
  constructor(private dialogRef: MatDialogRef<CreateGroupComponent>) { }

  ngOnInit(): void {
    this.groupBlock = new FormGroup({
      name: new FormControl(''),
      color: new FormControl('#d35bd8'),
      emoji: new FormControl('')
    })
    this.groupBlock.valueChanges.subscribe(() => this.changeGroupInfo());
  }
  changeGroupInfo() {
    console.log(this.groupBlock.value)
  }
  create() {
    const {name, color, emoji } = this.groupBlock.value;
    if (name) {
      this.dialogRef.close({name, color, emoji})
    } else {
      this.error = 'Por favor, introduce el nombre del grupo.';
    }
  }
  cancel() {
    this.dialogRef.close();
  }
}
