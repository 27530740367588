<div class="content" role="main" *ngIf="inited">
    <div class="container">
        <mat-toolbar class="toolbar-container" style="min-height: 75px;max-height: 75px;">
          <div class="modules-container">
              <div class="module">
                <img src="assets/total-leads-icon.svg" alt="Total leads" class="custom-icon" />
                <div class="module-text">
                      <span>Total leads</span>
                      <span class="module-number">{{ app.totalLeads }}</span>
                </div>
              </div>
              <div class="vertical-line"></div>
              <div class="module">
                <img src="assets/bolsa-de-dinero.svg" alt="Acumulado" class="custom-icon" />
                  <div class="module-text">
                      <span>Acumulado</span>
                      <span class="module-number">{{ app.totalAmount | number : '1.0-0' }} {{app.selectedSymbol}}</span>
                  </div>
              </div>
              <div class="vertical-line"></div>
              <div class="module">
                <img src="assets/dinero.svg" alt="Ganado" class="custom-icon" />
                  <div class="module-text">
                      <span>Ganado</span>
                      <span class="module-number">{{ app.totalProfit | number : '1.0-0' }} {{app.selectedSymbol}}</span>
                  </div>
              </div>
              <div class="vertical-line"></div>
            </div>
            <div class="module refresh-container">
                <img matTooltip="Actualizar Conversaciones" src="assets/refresh-icon.png" alt="Refresh" class="custom-icon left-side-icon"  (click)="refreshConversations()" />
            </div>
            <span class="header-spacer"></span>
            <span *ngIf="app.conversationSyncMessage" style="font-size: 14px;
            font-style: italic;
            color: #d1d1d1;">Sincronización en curso, seguimos cargando tus conversaciones...</span>
            <span class="header-spacer"></span>
            <div class="right-side-icon-container">
                <div class="currency-container">
                <mat-form-field appearance="fill">
    
                    <img [src]="selectedFlag" class="currency-flag" > {{app.selectedSymbol}}
                    <mat-label>Divisa</mat-label>
                    <mat-select #currencySelect [(value)]="selectedCurrency" (selectionChange)="onCurrencyChange($event.value)">
                        <mat-option *ngFor="let currency of currencies" [value]="currency.code">
                            <img [src]="currency.flag" class="currency-flag"> {{ currency.code }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                </div>
                <div class="vertical-line-right"></div>
                <img [src]="app.showCheck ? 'assets/modo-privado.svg' : 'assets/modo-privado-select.svg'"
                    [matTooltip]="app.showCheck ? '' : 'Privado'"                 
                    alt="Modo Privado" 
                    class="right-side-icon" 
                    (click)="toggleModoPrivado()" />
            
                <img [src]="app.showUnread ? 'assets/no-leidos-active.svg' : 'assets/no-leidos-inactive.svg'" 
                    [matTooltip]="app.showUnread ? 'Todos' : 'No leídos'" 
                    alt="No Leídos" 
                    class="right-side-icon" 
                    (click)="toggleNoLeidos()" />

                <img src="assets/help.svg" alt="Ayuda"  matTooltip="Ayuda" class="right-side-icon" (click)="helpModal()" />
                <img matTooltip="Conexiones" src="assets/manage-accounts.png" class="right-side-icon" (click)="openConnectionModal()" />
            </div>
        </mat-toolbar>
    </div>
    <div class="card-container" cdkDropListGroup>
        <ng-container *ngFor="let card of app.cards">
            <app-contact-cart [card]="card" [dropFunction]="drop" [dragFunction]="drag" [createBox]="createBox" [showMainView]="showMainView" [columnLength]="app.cards.length" [updatePosition]="updatePosition" [newData]="newData"></app-contact-cart>
        </ng-container>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" [fullScreen]="true" template="<img width='50%' src='./assets/loading_white.gif' />"></ngx-spinner>
