import { Component, OnInit, OnDestroy, Inject, ChangeDetectorRef, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from '../app.service';
import { ApiService } from '../shared/api.service';
import { SharedDataService } from '../services/shared-data.service';
import { Message } from 'src/app/shared/types';
import { MessageService } from '../shared/message.service';

@Component({
  selector: 'app-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.scss']
})
export class MainViewComponent implements OnInit, AfterViewInit {
  private intervalId: any;
  private messagesDiv:HTMLElement;
  private isGoing: boolean;
  @Output() messagesUpdated = new EventEmitter<void>();

  messages: any = [];
  groupList=[];
  defaultGroupId:any;
  participantId:any;
  contactId:any;
  messageintervalId: any;
  alreadyScrolled: boolean = false;
  @ViewChild('mainPage') mainRef: ElementRef<any> | undefined;
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any, 
      private api: ApiService, 
      private dialog: MatDialogRef<MainViewComponent>,
      private cdr: ChangeDetectorRef,
      public app: AppService,
      private shareService: SharedDataService,
      private messageService: MessageService
    ) { 
      this.messagesDiv = document.getElementsByClassName("list list-messages message-scroll w-left")[0] as HTMLElement;
      this.intervalId = 0;
      this.isGoing = false;
    }

    setUpdateInterval() {
      setInterval(() => {
        this.loadMessages();
        }, 200);
      }

  async ngOnInit(): Promise<void> {
    this.messagesDiv = document.getElementsByClassName("list list-messages message-scroll")[0] as HTMLElement;
    this.groupList=this.data.groupList;
    this.defaultGroupId=this.data.defaultGroupId;
    this.contactId=this.data?.contactId?.id;
    this.participantId = this.data?.contactId?.participantId;
    this.messages = this.app.allConversationsMessages[this.contactId];
    if(this.messages?.length != null && this.messages?.length>0) this.app.conversationInitiallyLoaded[this.contactId] = true;
    var loadingDiv = document.getElementById("loadingDiv");
    if (loadingDiv && this.app.conversationInitiallyLoaded[this.contactId])
    {
      this.messageService.notifyMessageReceived();
      loadingDiv.style.display = "none";
    }

    if(!this.app.conversationInitiallyLoaded[this.contactId] && this.data?.contactId?.conversationType=='whatsapp')
    {
      const messagesResponse = await this.fetchConversationMessages(this.contactId, 200);     
        this.app.allConversationsMessages[this.contactId] = messagesResponse;
        this.messages = this.app.allConversationsMessages[this.contactId];
        this.app.conversationInitiallyLoaded[this.contactId] = true;
        this.app.conversationFullyLoaded[this.contactId] = true;
    }
    if(!this.app.conversationInitiallyLoaded[this.contactId] && this.data?.contactId?.conversationType=='instagram')
    {
      this.fetchInstagramMessages(this.contactId);
    }
    if(!this.app.conversationInitiallyLoaded[this.contactId] && this.data?.contactId?.conversationType=='messenger')
    {
      this.fetchFacebookMessages(this.contactId);
    }
    this.loadMessages();
    this.setUpdateInterval();

    this.messageService.messageReceived.subscribe((message) => {
      this.messagesUpdated.emit();
    });
  }

  fetchInstagramMessages = (conversationId:string) =>
    {
        this.api.sendGetRequest({ action: 'Message/GetInstagramConversationMessages', params: { conversationId: conversationId} })
            .subscribe((response) => {
            response.messages.forEach((message:any) => {
            message.timestamp = this.convertLocal(message.timestamp);
            if(message.type == 5 && (message.fileDownloadUrl == null || message.fileDownloadUrl == "") )
            {
              message.body = "Los mensajes de audio antiguos no son compatibles en este momento."; 
              message.style= "deleted"
              message.type = 1;
            }
          });
          this.app.allConversationsMessages[conversationId] = response.messages;
          this.app.allConversationsFetchToken[conversationId] = response.nextFetch;
          this.app.conversationInitiallyLoaded[this.contactId] = true;
          this.app.conversationFullyLoaded[this.contactId] = true;
          if(response.nextFetch == "")
            this.app.conversationFullyLoaded[conversationId]= true;
        });
    }

    fetchFacebookMessages = (conversationId:string) =>
    {
        this.api.sendGetRequest({ action: 'Message/GetFacebookConversationMessages', params: { conversationId: conversationId} })
        .subscribe((response) => {
          response.messages.forEach((message:any) => {
          message.timestamp = this.convertLocal(message.timestamp);
        });
        this.app.allConversationsMessages[conversationId] = response.messages;
        this.app.allConversationsFetchToken[conversationId] = response.nextFetch;
        }); 
    }

   async fetchConversationMessages(conversationId: string, count: number): Promise<any[]> {
      return new Promise((resolve, reject) => {
        this.api.sendGetRequest({
            action: 'Message/GetChatMessages',
            params: { contactNumber: conversationId, limit: count }
        }).subscribe(
            response => resolve(response),
            error => reject(error)
        );
    });
    }

  convertLocal(utcTimestamp: number): Date {
    const utcDate = new Date(utcTimestamp);
    const localDate = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
    );
    return localDate;
  }
  private loadMessages(): void {
    this.messages = this.app.allConversationsMessages[this.contactId];
    if(this.messages)
    {
      for (let i = 0; i <  this.messages.length; i++) {
        if ( this.messages[i]['body'])
          this.messages[i]['body'] = this.app.wrapLinksWithAnchorTag( this.messages[i]['body']);
      }
    }
    var loadingDiv = document.getElementById("loadingDiv");
    if (loadingDiv && this.app.conversationInitiallyLoaded[this.contactId])
    {
      this.messageService.notifyMessageReceived();
      loadingDiv.style.display = "none";
    }
  }
  ngOnDestroy ():void {
  }
  ngAfterViewInit(): void {
      const dialogElement:HTMLElement = this.mainRef?.nativeElement;
  }

  updateMessage = (msg: any, id?:string) => {
    if (id) {
      const exist = this.messages.findIndex((message: any) => message.id === id);
      if (exist!==-1) {
        const existEle = this.messages[exist];
        if (msg.delete) {
          this.messages = this.messages.filter((message: any) => message.id !== id);
        } else {
          for (const key in msg) {
            existEle[key] = msg[key];
          }
        }
      }
    } else {
      if (!this.app.allConversationsMessages[this.contactId].some(existingMsg => existingMsg.id === msg.id)) {
        this.app.allConversationsMessages[this.contactId].push(msg);
      } else {
        console.log('A message with this ID already exists.');
      }
        const listEl = document.querySelector('.list-messages');
        listEl?.scrollTo({top: listEl.scrollHeight, behavior: 'smooth'});
    }
  }
  closeSidebar(isClose:string='') {
    if (isClose) {
      this.app.showSidebar = true;
    } else {
      this.app.showSidebar = false;
    }
  }

  //close dialog of chat popup
  closeMessage(){
    this.dialog.close();
  }
}