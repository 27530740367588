import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'; 
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DateTimeSelectorComponent } from 'src/app/date-time-selector/date-time-selector.component';
// import * as lamejs from 'lamejs';
// import { MediaRecorder } from 'dom-mediacapture-record';
declare var MediaRecorder: any;
declare var webkitAudioContext: any;
declare var lamejs: any;
@Component({
  selector: 'app-media-recorder',
  templateUrl: './media-recorder.component.html',
  styleUrls: ['./media-recorder.component.scss']
})
export class MediaRecorderComponent implements OnInit {

  mediaRecorder: any;
  audioChunks: any[] = [];
  recordingTime: number = 0;
  timer: any;
  status: string = 'start';
  blobUrl:any;
  blob: Blob = new Blob;
  @Input() name: string = '';
  @Input() conversationType: string = 'whatsapp';
  @Input()   participantId  : string = '';
  @Output() endAudioRecord: EventEmitter<any> = new EventEmitter<any>();
  @Input() updateMessage: Function = new Function;
  constructor(private sanitizer: DomSanitizer, private api: ApiService, public app: AppService, private dialog: MatDialog) { }

  ngOnInit(): void {
  }



start(): void {
  // Early exit if MediaRecorder is not supported
  if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia || !MediaRecorder) {
    this.app.showErrorToastr("MediaRecorder is not supported in this browser");
    return;
  }
  const MEDIA_RECORDER_CHUNK_TIME = 3000; 
  const RECORDING_UPDATE_INTERVAL = 1000;

  navigator.mediaDevices.getUserMedia({ audio: true })
    .then(stream => {
      this.mediaRecorder = new MediaRecorder(stream);
      if(this.status !== 'pause')
      {
        this.audioChunks = [];
        this.recordingTime = 0;
      }
      this.mediaRecorder.addEventListener('dataavailable', (event: any) => {
        if (event.data.size > 0) {
          this.audioChunks.push(event.data);
        }
        if (this.status === 'done') {
          if(this.blobUrl) {
            URL.revokeObjectURL(this.blobUrl);
            this.blobUrl = '';
          }
          this.blob = new Blob(this.audioChunks, { type: "audio/wav" });
          this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.blob));
        }
      });

      this.mediaRecorder.start(MEDIA_RECORDER_CHUNK_TIME);
      this.status = 'recording';
      if(this.status !== 'pause')
      {
        this.timer = setInterval(() => {
          this.recordingTime += 1;
        }, RECORDING_UPDATE_INTERVAL);
      }
    })
    .catch(error => {
      console.error("Error accessing media devices:", error);
      this.app.showErrorToastr("Error accessing media devices");
    });
  }

  done() {
    if (this.status === 'recording') {
      this.mediaRecorder.stop();
    } else {

      if(this.blobUrl) {
        URL.revokeObjectURL(this.blobUrl);
        this.blobUrl = '';
      }
      this.blob = new Blob(this.audioChunks, { type: "audio/wav" });
      this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.blob));
      this.clear(false);
    }
    this.status = 'done';
  }

  pause() {
    this.mediaRecorder.stop();
    clearInterval(this.timer);
    this.status = 'pause';
  }

  resume() {
    this.status = 'recording';
    this.mediaRecorder.start();
    this.timer = setInterval(() => {this.recordingTime += 1}, 1000)
  }

  clear(isEnd=true) {
    if (this.mediaRecorder) {
      if (this.mediaRecorder.state === 'recording') this.mediaRecorder.stop();
      const mediaStream = this.mediaRecorder.stream;
      console.log(mediaStream)
      if (mediaStream) {
        mediaStream.getTracks().forEach((track:any) => track.stop());
      }
      this.mediaRecorder = null;
    }
  clearInterval(this.timer);
    this.recordingTime = 0
    this.audioChunks = [];
    if (isEnd) {
      this.endAudioRecord.emit();
      this.status = 'start';
      URL.revokeObjectURL(this.blobUrl);
      this.blobUrl = '';
      this.blob = new Blob();
    }
  }

  delete() {
    this.clear(); 
  }

  async send() {
    const blob = this.blob;
    this.blob = new Blob();
    const blobUrl = this.blobUrl;
    this.clear();

    try {
      const mp3Blob = await this.convertToMp3(blob);
      const id = "true_" + this.name + "@c.us_" + Date.now();
      this.updateMessage({
          timestamp: Date.now(),
          id: id,
          body: "",
          fromMe: true,
          type: 5,
          fileDownloadUrl: blobUrl,
          uploading: true,
        });
      const formData = new FormData();
      formData.append('audio', mp3Blob);
      formData.append('name', this.name);
      formData.append('conversationType',this.conversationType);
      formData.append('participantId',this.participantId);
      var a = await this.api.sendFormDataRequest({ action: 'Message/SendAudio', formData: formData }).toPromise();
      this.updateMessage({ uploading: false }, id);
    }
    catch (error) {
        console.log('Error in send:', error);
    }
  }

  private convertLocalToUTC(date: Date): string {
    return new Date(date.toISOString()).toISOString();
  }

  async rescheduleSend() {
    const dialogRef = this.dialog.open(DateTimeSelectorComponent, {
      width: '250px'
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const blob = this.blob;
        this.blob = new Blob();
        const blobUrl = this.blobUrl;
        this.clear();
        try {
          const mp3Blob = await this.convertToMp3(blob);
          const id = "true_" + this.name + "@c.us_" + Date.now();
          const date = new Date(result);    
          const nowDateUtc = this.convertLocalToUTC(new Date());
          const sendDate = this.convertLocalToUTC(date);
          const lastMessageId = this.app.allConversationsMessages[this.name][this.app.allConversationsMessages[this.name].length - 1].id;
          var contactId = this.conversationType == 'whatsapp' ? this.name : this.participantId
          
          this.updateMessage({
              timestamp: Date.now(),
              id: id,
              body: "",
              fromMe: true,
              type: 5,
              fileDownloadUrl: blobUrl,
              uploading: true,
              style: 'rescheduled',
              rescheduled: true,
              sendDate: sendDate
            });
          var uploadedUrl = await this.fileUpload(mp3Blob);
          if (uploadedUrl) {
            await this.api
            .sendPostRequest({
                action: 'Message/RescheduleSend',
                body: {
                  recipientId: contactId,
                  platform: this.conversationType,
                  messageType: 5,
                  message: "",
                  createdDate: nowDateUtc,
                  sendDate: sendDate,
                  lastMessageId: lastMessageId,
                  fileName: "",
                  fileUrl: uploadedUrl,
                  convId: this.name,
                  generatedId: id
                },
            })
            .toPromise();
          }
          this.updateMessage({ uploading: false }, id);
        }
        catch (error) {
            console.log('Error in send:', error);
        }
      }});
  }

  fileUpload(blob: any) {
    return new Promise((resolve, reject) => {
      console.log(blob.type, blob.name);
      let extension = this.conversationType == "whatsapp" ? "mp3" : "wav";
      var contactId = this.conversationType == 'whatsapp' ? this.name : this.participantId
      const formData = new FormData();
      formData.append('file', blob);
      formData.append('apiKey', Date.now() + '');
      formData.append('contactId', contactId);
      formData.append('extension', extension);
      console.log(blob.type);
      console.log("form data : " ,formData);
      this.api
        .sendFormDataRequest({ action: 'File/upload', formData: formData })
        .subscribe((res) => {
          if (res) {
            console.log("result",res);
            resolve(res);
          } else {
            console.log("result",res);
            reject('Server connection error!');
          }
        });
    });
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;
    return `${this.padZero(minutes)}:${this.padZero(secondsLeft)}`;
  }

  padZero(value: number): string {
    return value.toString().padStart(2, '0');
  }

  downloadBlob(blob: Blob) {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'filename.ogg';
    link.click();
    URL.revokeObjectURL(url);
    link.remove();
  }

  processBlob(blob: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        resolve(reader.result as ArrayBuffer);
      }
      reader.onerror = () => {
        reject('Error reading the Blob data.');
      }
      reader.readAsArrayBuffer(blob);
    })
  }

  async convertToMp3(blob : Blob) :  Promise<Blob> {

    const audioBuffer : any = await this.blobToAudioBuffer(blob);
    var samples = new Int16Array(audioBuffer.getChannelData(0).length);
    for (let i = 0; i < samples.length; i++) {
        const s = Math.max(-1, Math.min(1, audioBuffer.getChannelData(0)[i]));
        samples[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
    }

    const mp3encoder = new lamejs.Mp3Encoder(1, audioBuffer.sampleRate, 128);
    const mp3Data = [];
    const mp3buf = mp3encoder.encodeBuffer(samples);

    if (mp3buf.length > 0) {
        mp3Data.push(mp3buf);
    }

    const mp3bufEnd = mp3encoder.flush();
    if (mp3bufEnd.length > 0) {
        mp3Data.push(mp3bufEnd);
    }

    const mp3Blob = new Blob(mp3Data, { type: "audio/mp3" });
    return mp3Blob;
  }

  blobToAudioBuffer(blob: Blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const arrayBuffer = reader.result;
            if (arrayBuffer instanceof ArrayBuffer) {
                const audioContext = new (window.AudioContext || webkitAudioContext)();
                audioContext.decodeAudioData(arrayBuffer, decodedData => resolve(decodedData), e => reject(e));
            } else {
                reject(new Error("Failed to convert Blob to ArrayBuffer"));
            }
        };
        reader.readAsArrayBuffer(blob);
    });
  }
}