import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedDataService } from 'src/app/services/shared-data.service';

@Component({
    selector: 'app-group-list',
    templateUrl: './group-list.component.html',
    styleUrls: ['./group-list.component.scss']
})
export class GroupListComponent implements OnInit {
    @Input() defaultGroupId: any;
    @Input() groupList: any = [];
    @Input() contactId: any = null;
    groupEventData: any;

    ngOnInit(): void {
         console.log(this.groupList);
    }
    
    constructor(private sharedDataService: SharedDataService) { }
    changeGroup(selectedGroupId: any) {
        console.log('Selection changed:', selectedGroupId);
        this.sharedDataService.sendData({ 'selectedGroupId': selectedGroupId, 'contactId': this.contactId });
    }
}