<div class="group-form">
    <h3>Crear Grupo</h3>
    <mat-divider></mat-divider>
    <form [formGroup]="groupBlock" fxLayout="column">
        <div>
            <mat-form-field>
                <mat-label>Nombre: </mat-label>
                <input type="text" matInput formControlName="name" />
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Color: </mat-label>
                <input type="color" matInput formControlName="color"/>
            </mat-form-field>
        </div>
    </form>
    <div *ngIf="error !== ''"style="color: red;" [innerHTML] ="error" class="pl-50"></div>
    <div style="margin-top: 1rem">
        <button mat-raised-button color="primary" (click)="create()">Crear</button>
        <button style="margin-left: 1rem" mat-button color="primary" (click)="cancel()">Cancelar</button>
    </div>
</div>