import { Component, OnInit, Input,ViewChild,ElementRef, EventEmitter,Output } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { LeadUpdateService } from 'src/app/services/lead-update.service';


@Component({
  selector: 'app-sidebar-right',
  templateUrl: './sidebar-right.component.html',
  styleUrls: ['./sidebar-right.component.scss']
})
export class SidebarRightComponent implements OnInit {
  @Input() otherUser: any = null; // the user you are chatting,depends on selectedChat
  @ViewChild('leadValueInput') leadValueInput?: ElementRef;
  @Output() amountWonUpdated = new EventEmitter<void>();

 
  panelOpenState = false;
  tags: string[] = [];
  newTag: string = '';
  leadValue: number = 0;
  leadWon: boolean = false;
  editMode: boolean = false;
  filteredMessages: any[] = [];
  constructor(public app: AppService, private api: ApiService,
    private leadUpdateService: LeadUpdateService){}
    

  ngOnInit(): void {
    this.getTags();
    this.leadValue = this.otherUser?.contactId.amount ?? 0;
    this.leadWon = this.otherUser?.contactId.won;
    console.log("dakxi le kjayn",this.filteredMessages);
    this.setUpdateInterval();
    this.filterRescheduledMessages();
  }
  filterRescheduledMessages() {
    const messages = this.app.allConversationsMessages[this.otherUser?.contactId.id];
    if (messages) {
      this.filteredMessages = messages.filter(msg => msg.rescheduled);
    }
  }

  cancelRescheduledMessage(messageId: any) {
    // Remove from filtered messages
    this.filteredMessages = this.filteredMessages.filter(message => message.id !== messageId);
    console.log("Removed msg id : ", messageId);
    if (this.app.allConversationsMessages[this.otherUser?.contactId.id]) {
        this.app.allConversationsMessages[this.otherUser?.contactId.id] =
            this.app.allConversationsMessages[this.otherUser?.contactId.id].filter(message => message.id !== messageId);
    }

    // Make an API call to delete the message from the backend, including the userId for security
    this.api.sendPostRequest({
        action: 'Message/DeleteRescheduledMessage',
        params: { messageId: messageId}
    }).subscribe({
        next: (res) => console.log('Message cancellation successful'),
        error: (err) => console.error('Error cancelling message', err)
    });
  }

  
  setUpdateInterval() {
    setInterval(() => {
      this.filterRescheduledMessages();
      }, 200);
    }

  goBack() {
    this.app.showSidebar = false;
  }
  getTags() {
    this.api.sendGetRequest({action: 'Contact/GetTags', params: {user: this.api.userId, contactId: this.otherUser.contactId.id}}).subscribe(res => {
      this.tags = res;
    })
  }
  addTag() {
    if (this.newTag) {
      this.api.sendPostRequest({action: 'Contact/setTag', params: {phone: this.otherUser.contactId.id, tag: this.newTag}}).subscribe(res => {
        this.newTag = '';
        this.getTags();
      })
    }
  }
  deleteTag(tag: string) {
    this.api.sendPostRequest({action: 'Contact/removeTag', params: {phone: this.otherUser.contactId.id, tag: tag}}).subscribe(res => {
      this.getTags();
    })
  }

  toggleEditMode(mode: boolean) {
    this.editMode = mode;
    if (mode) {
      setTimeout(() => this.focusInput(), 0);
    }
    else
    {
      if (this.leadValue === null || this.leadValue === undefined ) {
        this.leadValue = 0;
      }
      this.otherUser.contactId.amount = this.leadValue;
      this.otherUser.contactId.won = this.leadWon;
      this.api
      .sendPostRequest({
        action: 'Contact/saveContact',
        params: { phone: this.otherUser.contactId.id, amount:  this.leadValue, contactType: this.otherUser.contactId.conversationType,
                  isWhatsappConnected: this.app.isWhatsappConnected, isFacebookConnected: this.app.isFacebookConnected, isInstagramConnected: this.app.isInstagramConnected
                },
      }).subscribe((res) => {
        this.otherUser.contactId.amount = this.leadValue;
        this.otherUser.contactId.won = this.leadWon;
        this.leadUpdateService.notifyLeadUpdated();
        this.app.totalAmount = res.leadsAmount;
        this.app.totalProfit = res.totalWon;
      });     
    }
  }

  focusInput() {
    if (this.editMode && this.leadValueInput) {
        this.leadValueInput.nativeElement.focus();
    }
  }
  onLeadWonChange() {
    this.api
    .sendPostRequest({
      action: 'Contact/UpdateWon',
      params: { phone: this.otherUser.contactId.id, won:  this.leadWon, contactType: this.otherUser.contactId.conversationType,
                isWhatsappConnected: this.app.isWhatsappConnected, isFacebookConnected: this.app.isFacebookConnected, isInstagramConnected: this.app.isInstagramConnected
              },
    }).subscribe((res) => {
      this.otherUser.contactId.won = this.leadWon;
    });     
  }

  findConversationById(conversationId: string) {
    for (const card of this.app.cards) {
      for (const conversation of card.conversations) {
        if (conversation.id === conversationId) {
          return conversation;
        }
      }
    }
    return null;
  }
}