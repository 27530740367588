import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaderResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ResType } from "./types";
import { Observable } from 'rxjs';
import { map,  tap} from 'rxjs/operators';

const apiEndPoint = 'https://generator-pipeline-api.azurewebsites.net/';
const apiEndPoint2 = 'https://back.generatorlanding.com';
//const apiEndPoint = 'https://localhost:7018'
@Injectable({
    providedIn: 'root'
})
export class ApiService {
    headers = {};
    userId = '';
    userKey = '';
    arrAffinityCookie = '';

    constructor(private http: HttpClient) {
    }

    updateUserKey(key: string) {
        this.userKey = key;
    }

    updateArrAffinityCookie(cookie : string){
        this.arrAffinityCookie = cookie;
    }

    private getRequestHeader(): HttpHeaders{
        if (localStorage.getItem(this.userKey)) {
            const headersConfig = {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'Auth-Key': this.userKey,
                'ARRAffinityCookie': localStorage.getItem(this.userKey) as string,
            };
            return new HttpHeaders(headersConfig);
        }
        const headersConfig = {
            'Content-Type': 'application/json',
            'accept': 'text/plain',
            'Auth-Key': this.userKey,
            'ARRAffinityCookie': '',
        };
        return new HttpHeaders(headersConfig);
    }

    private getRequestOptions(): { headers: HttpHeaders } {
        const headers = this.getRequestHeader();
        return { headers };
    }

    sendGetRequest({ action, params = {} }: { action: string, params?: any }): Observable<any> {
        const urlParams = new URLSearchParams(params);
        const url = `${apiEndPoint}/${action}?${urlParams}`;
        const options = this.getRequestOptions();
        return this.http.get<any>(url, options).pipe(
            map((res : any) => {
                if (res.success) {
                    if (res.response.arrAffinity) {
                        localStorage.setItem(this.userKey, res.response.arrAffinity);
                        return res.response.realResponse;
                    }
                    if (res.response.realResponse){
                        return res.response.realResponse;
                  }
                    return res.response;
                } else {
                    throw new Error(res.errorMessage || 'Something error!');
                }
            })
        )
    }

    sendPostRequest({action, params = {}, body = {}} : {action: string, params?: any, body?:any}) {
        // params.userId = this.userId;
        const urlParams = new URLSearchParams(params);
        const url = `${apiEndPoint}/${action}?${urlParams}`;
        const options = this.getRequestOptions();
        return this.http.post<ResType>(url, body, options).pipe(
            map((res: ResType) => {
                if (res?.success) {
                    return res.response;
                } else {
                    console.log(res?.errorMessage);
                    return false;
                }
            })
        );
    }

 sendPostRequest2({action, params = {}, body = {}} : {action: string, params?: any, body?:any}) {
        // params.userId = this.userId;
        const urlParams = new URLSearchParams(params);
    const url = `${apiEndPoint2}/${action}`;
        const options = this.getRequestOptions();
        return this.http.post<ResType>(url, body).pipe(
            map((res: ResType) => {
                console.log("resultat",res);
                return res;
            })
        );
    }

    sendFormDataRequest({action, formData} : {action: string, formData: FormData}) {
        const url = `${apiEndPoint}/${action}`;
        const headers = new HttpHeaders({
            // 'Content-Type': 'multipart/form-data',
            'Auth-Key': this.userKey,
        });
        //const headers = this.getRequestHeader();
        return this.http.post<ResType>(url, formData, { headers }).pipe(
            map((res: ResType) => {
              if (res?.success) {
                return res.response;
              } else {
                console.log(res?.errorMessage);
                return false;
              }
            })
          );
    }
}