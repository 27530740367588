<div class="modal-backdrop" (click)="closeModal()"></div>
<div class="modal">
  <div class="modal-content">
    <span class="close" (click)="closeModal()">&times;</span>
    
    <div class="modal-header">
      <h2>Como configurar el pipeline con WhatsApp API</h2>
    </div>
    
    <div class="video-container">
      <!-- Embed Vimeo video -->
      <iframe src="https://player.vimeo.com/video/1001295266" 
              width="100%" height="360" frameborder="0" 
              allow="autoplay; fullscreen; picture-in-picture" 
              allowfullscreen>
      </iframe>
    </div>
    
    <div class="modal-footer">
      <p>Atención: el número de WhatsApp para usar con la API no debe estar conectado
         a ninguna cuenta de WhatsApp. Solo debes ponerla en móvil/celular para que
         te llegue el SMS de confirmación.</p>
      <p>Después de eso ya puedes quitar la SMS o desconectar la eSIM.</p>
    </div>
  </div>
</div>