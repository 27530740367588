import {
  CdkDragDrop,
  CdkDragStart,
  copyArrayItem,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AppService } from './app.service';
import { MessageService } from './shared/message.service';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { AES, enc } from 'crypto-js';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ApiService } from './shared/api.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { timer,Subscription  } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreateGroupComponent } from './create-group/create-group.component';
import { CardType } from './shared/types';
import { MainViewComponent } from './main-view/main-view.component';
import { ToastrService } from 'ngx-toastr';
import { SharedDataService } from './services/shared-data.service';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { HelpLogoComponent } from './shared/help-logo/help-logo.component';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { SignalRService } from './services/signalr.service';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { retry } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { LeadUpdateService } from 'src/app/services/lead-update.service';
import { ConnectionModalComponent } from './shared/connection-modal/connection-modal.component';
import { InitConnectionModalComponent } from './shared/init-connection-modal/init-connection-modal.component';
import { WhatsappApiConfigComponent } from './whatsapp-api-config/whatsapp-api-config.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'trello';
  //cards: CardType[] = [];
  dialogWidth = '90%';
  inited: boolean = false;
  scrollPosition = {};
  cardScrollPosition :any= {};
  groupList: any = [];
  facebookConversationsIdsList : any = [];
  instagramConversationsIdsList : any = [];
  whatsappApiConversationsIdsList: any = [];
  whatsappConversationsIdsList : { id: string, lastUpdate: string }[] = [];
  search: string = '';
  filteredData: any = [];
  waitRequest: boolean = false;
  isEditTitle: boolean = false;
  newData:any = {};
  inactivityTimer: any;
  inactivityDuration = 30000;
  isUserInactive: boolean = false;
  modoPrivadoActive: boolean = false;
  noLeidosActive: boolean = false;
  selectedCurrency: string = 'USD';
  requestCount: number = 0;
  tripleMultiplier = 3;
  initialLoad = false;
 conversationsCallCount = 1;
  readCountList: { conversationId: string, count: number }[] = [];
  private lastGetConversationsTime: number = 0;
  private recentMessagesCache = new Map<string, { message: any, unreadCount: number, timestamp: number, amount: number }>();
  dragByThisDevice: boolean = false; // Variable to store interval ID
  selectedFlag: string = 'assets/flags/usd.svg';
  currencies = [
    { code: 'USD', flag: 'assets/flags/usd.svg', symbol: '$' },
    { code: 'EUR', flag: 'assets/flags/euro.svg', symbol: '€' },
    { code: 'BRL', flag: 'assets/flags/brazil.svg', symbol: 'R$' },
    { code: 'ARS', flag: 'assets/flags/argentina.svg', symbol: '$' },
    { code: 'VEF', flag: 'assets/flags/venezuela.svg', symbol: 'Bs' },
    { code: 'COP', flag: 'assets/flags/colombia.svg', symbol: '$' },
    { code: 'USD', flag: 'assets/flags/ecuador.svg', symbol: '$' },
    { code: 'CLP', flag: 'assets/flags/chile.svg', symbol: '$' },
    { code: 'PEN', flag: 'assets/flags/peru.svg', symbol: 'S/.' },
    { code: 'MXN', flag: 'assets/flags/mexico.svg', symbol: '$' },
  ];
  contactsIdList: any[] = [];

  private viewportSubscription: Subscription = new Subscription();
  private currentContactId: any;
  private audio:HTMLAudioElement;
  constructor(
    private cdr: ChangeDetectorRef,
    private api: ApiService,
    public app: AppService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private viewportRuler: ViewportRuler,
    private sharedDataService: SharedDataService,
    public signalRService: SignalRService,
    private leadUpdateService: LeadUpdateService

  ) {
    this.audio = new Audio();
    this.audio.src = "../assets/beep/notification-sound.wav";
    this.audio.volume = 0.3;
    this.audio.load();
  }
  LastMessagesWritedyClearing() {
    setInterval(() => {
        const currentTime = Date.now();
        const durationToKeepMessage = 60000; // 2 seconds in milliseconds
        this.app.lastMessagesWritedLocally = this.app.lastMessagesWritedLocally.filter((item) => {
            return currentTime - item.timestamp < durationToKeepMessage;
        });
      }, 120000); // Check every 1 second to keep the UI updated
}


  getSelectedCurrency()
  {
    this.selectedCurrency = "USD";
    this.api.sendGetRequest({ action: 'Contact/getUserCurrencyPreference'})
    .subscribe((selectedCurrency) => {
      if(selectedCurrency != null && selectedCurrency.length>0)
        {
          this.selectedCurrency = selectedCurrency;
          const selectedCurrencyObj = this.currencies.find(currency => currency.code === this.selectedCurrency);
          if(selectedCurrencyObj!=null && selectedCurrencyObj?.flag != null)
          this.selectedFlag = selectedCurrencyObj?.flag;
          if(selectedCurrencyObj!=null && selectedCurrencyObj?.symbol != null)
          this.app.selectedSymbol = selectedCurrencyObj?.symbol;
        }
    });
  }

  onCurrencyChange(newCurrency: string) {
    const selectedCurrencyObj = this.currencies.find(currency => currency.code === newCurrency);
      if (selectedCurrencyObj) {
      this.selectedCurrency = newCurrency;
      this.selectedFlag = selectedCurrencyObj.flag;
      this.app.selectedSymbol = selectedCurrencyObj.symbol;
      this.api.
        sendPostRequest
          ({ action: 'Contact/saveUserCurrencyPreference' ,
            params: { currencyCode: this.selectedCurrency},})
        .subscribe(()=> {

      });
    } else {
      console.log(`Currency with code ${newCurrency} not found in the currencies array.`);
    }
  }

  ngOnInit(): void {
    this.setViewPortSize();
  
    this.route.queryParams.subscribe(async (params) => {
      if (params.user) {
        this.spinner.show();
        const user = decodeURIComponent(params.user);
        console.log("userkey : ", user);
        var isAllowed = false;
        try {
          const res = await this.api.sendPostRequest2({
            action: 'api/Usuarios/uuidToUser',
            body: { hash: user.replace(/\s/g, '+') }
          }).toPromise();
          isAllowed = res as unknown as boolean;
          console.log("response howa : ", res);
        } catch (error) {
          console.log("Error when posting to api/Usuarios/uuidToUser",error)
         this.spinner.hide();
          Swal.fire({
            title: 'Error de Acceso!',
            text: 'No estás conectado actualmente. Por favor, conecte de nuevo su cuenta para poder continuar.',
            icon: 'question',
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
          });
        }
          if (user && isAllowed) {
          this.api.updateUserKey(user.replace(/\s/g, '+'));
          const userId = this.decryptString(
            'UJgidYgQbChhieUUGrzfN9cKGD69jHp1',
            user.replace(/\s/g, '+')
          );
          if (userId) this.api.userId = userId;
          console.log(userId);
          this.checkIsConnected();
          this.app.checkIsConnectedEvent.subscribe(() => {
            this.checkIsConnected();
          })
          this.getSelectedCurrency();
          this.app.showLoadingEvent.subscribe(() => {
            this.spinner.show();
          })
          this.app.lastMessagesWritedLocally.push({
            message: "",
            timestamp: Date.now()
          });
        
          this.setupInactivityTimer();
          this.LastMessagesWritedyClearing();
          this.signalRService.startConnection();
          this.signalRService.addMessageListener();
          this.signalRService.addRefreshListener();
          this.signalRService.addWonListener();
          this.signalRService.addLeadsAmountListener();
          this.signalRService.addGroupUpdateListener();
          this.signalRService.updateInstagramStatus();
          this.signalRService.addNewInstaMessageListener();
          this.signalRService.addNewFacebookMessageListener();
          this.signalRService.addRemoveRescueduledListener();
          this.signalRService.addApiListener();
          this.signalRService.addHandleSuccessListener();
          this.app.showSpinnerEvent.subscribe(() => {
            this.spinner.show();
          });
          this.signalRService.groupingUpdated.subscribe(() => {
            if (!this.dragByThisDevice) {
              this.updateGroupList();
            }
            this.dragByThisDevice = false;
          });
        
          this.signalRService.instagramConnectionStatus.subscribe((status: boolean) => {
            if (this.app.initiatedFromConnectionModal) {
              this.app.initiatedFromConnectionModal = false;
              if (status) {
                this.connectionUpdated();
              }
            }
            this.app.notifyFacebookStatusChange(true)
          });
          this.signalRService.messageApiReceived.subscribe((newMessage)=>
          {
            this.handlewhatsappApiMessage(newMessage);
          }
          );
          this.signalRService.facebookNewMessage.subscribe((newMessage) => {
            var appendMessage = (!this.app.lastMessagesWritedLocally.some(item => item.message == newMessage.message) && newMessage.messageType == 1) || (newMessage.messageType != 1 && !newMessage.fromMe);
            if(newMessage.append) appendMessage = true;
            this.handleMetaNewMessage(newMessage, "messenger", appendMessage);
          });
          this.signalRService.instagramNewMessage.subscribe((newMessage) => {
            console.log(newMessage);
            var appendMessage = (!this.app.lastMessagesWritedLocally.some(item => item.message == newMessage.message) && newMessage.messageType == 1) || (newMessage.messageType != 1 && !newMessage.fromMe);
            if(newMessage.append) appendMessage = true;
            this.handleMetaNewMessage(newMessage, "instagram", appendMessage);
          });
        
          this.signalRService.leadsAmountUpdated.subscribe((leadsAmount) => {
            this.app.totalAmount = leadsAmount; });
          this.signalRService.wonUpdated.subscribe((wonAmount) => {
            this.app.totalProfit = wonAmount;
          });
          this.signalRService.botSucceed.subscribe((success)=>
          {
            this.app.isWhatsappAPIConnected = success;
          });
          this.signalRService.messageReceived.subscribe((newMessage) => {
            this.handleWhatsappNewMessage(newMessage);
          });
          this.app.markAsReadEvent.subscribe((conversationId)=>{
            var conversation = this.findConversationById(conversationId);
            this.recentMessagesCache.set(conversationId, {
              message: conversation.lastMessage,
              unreadCount: 0,
              timestamp: Date.now(),
              amount: conversation.amount
            });
          });
          
          this.signalRService.rescheduledSended.subscribe((rescheduledmsg) => {
            if (this.app.allConversationsMessages[rescheduledmsg.convId]) {
              this.app.allConversationsMessages[rescheduledmsg.convId] =
                  this.app.allConversationsMessages[rescheduledmsg.convId].filter(message => message.id !== rescheduledmsg.generatedId);
          }
          });
          //this.setBackGroundUpdateInterval();
          this.sharedDataService.getData().subscribe((data) => {
            this.moveConversation(data.selectedGroupId, data.contactId);
            this.api
              .sendPostRequest({
                action: 'Grouping/setGrouping',
                params: { groupingId: data.selectedGroupId, phone: data.contactId },
              })
              .subscribe((res) => {
                this.saveGrouping();
              });
          });
        } else {
          this.spinner.hide();
          Swal.fire({
            title: 'Error de Acceso!',
            text: 'No estás conectado actualmente. Por favor, conecte de nuevo su cuenta para poder continuar.',
            icon: 'question',
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
          });
        }
      }
    });
  }
  async refreshConversations()
  {
    this.spinner.show();
    this.updateGroupList();
  }
handlewhatsappApiMessage(newMessage:any)
{
  if(!newMessage.fromMe)
  {
  const conversationId = newMessage.leadId;
  const conversation = this.findConversationById(conversationId);
  console.log(conversation,"hahia conversation");
  if(conversation)
  {
    this.app.allConversationsMessages[conversationId].push(newMessage);
    if (newMessage.fromMe) {
      conversation.unreadMessages = 0;
    } else {
      if (this.isUserInactive || document.hidden) {
        this.audio.play();
      }
      conversation.unreadMessages += 1;
    }
  }
}
}
  async handleWhatsappNewMessage(newMessage: any) {
    setTimeout(async () => {
      const conversationId = !newMessage.fromMe ? newMessage.from : newMessage.to;
      const conversation = this.findConversationById(conversationId);
      if (conversation) {
        const newConvertedMessage = this.convertNewMessage(newMessage, "whatsapp");
        conversation.lastMessage = newConvertedMessage;
        conversation.lastUpdate = this.convertLocal(newMessage.t * 1000);
        this.sortConversationsInCard(this.findGroupingById(conversationId));
        
        if (!newMessage.fromMe) {
          this.appendNewMessage(conversationId, newMessage, "whatsapp");
          if (!this.app.allConversationsMessages[conversationId] || this.app.allConversationsMessages[conversationId].length <= 0) {
            try {
              const messagesResponse = await this.fetchMessagesForConversation(conversationId, 200);
              this.app.allConversationsMessages[conversationId] = messagesResponse;
            } catch (error) {
              console.error('Error fetching messages:', error);
            }
          }
        } else {
          if (!this.app.lastMessagesWritedLocally.some(item => item.message === newMessage.body) && newMessage.type == "chat") {
            this.appendNewMessage(conversationId, newMessage, "whatsapp");
          }
          if (newMessage.type != "chat" && newMessage.fileDownloadUrl && newMessage.fileDownloadUrl.length != "") {
            this.appendNewMessage(conversationId, newMessage, "whatsapp");
          }
          this.tripleMultiplier++;
        }
  
        if (newMessage.fromMe) {
          conversation.unreadMessages = 0;
        } else {
          if (this.isUserInactive || document.hidden) {
            this.audio.play();
          }
          conversation.unreadMessages += 1;
        }
        let updatedUnreadCount = conversation.unreadMessages;
        this.recentMessagesCache.set(conversation.id, {
          message: newConvertedMessage,
          unreadCount: updatedUnreadCount,
          timestamp: Date.now(),
          amount: conversation.amount
        });
  
        this.cdr.detectChanges();
        this.app.scrollToButtomEvent.emit();
      } else {
        this.updateGroupList();
      }
    }, 1000);
  }  

  handleMetaNewMessage(newMessage:any, platform:string,appendMessage:boolean = true)
  {
    const participantId = newMessage.participantId;
    const conversation = this.findConversationByParticipantId(participantId);
    if(conversation)
    {
      if(!newMessage.isDeleted)
      {
        const newConvertedMessage = this.convertNewMessage(newMessage,platform);
        conversation.lastMessage = newConvertedMessage;
        conversation.lastUpdate = this.convertLocal(newMessage.timestamp);
        this.sortConversationsInCard(this.findGroupingById(conversation.id));
        this.cdr.detectChanges();
        if(appendMessage)        
        this.appendNewMessage(conversation.id, newMessage, platform);
          if (newMessage.fromMe)
            conversation.unreadMessages = 0;
          else {     
            if (this.isUserInactive || document.hidden)
            this.audio.play();
          conversation.unreadMessages += 1;
          }
      
      let updatedUnreadCount = conversation.unreadMessages;
      this.recentMessagesCache.set(conversation.id, {
        message: newConvertedMessage,
        unreadCount: updatedUnreadCount,
        timestamp: Date.now(),
        amount: conversation.amount
      });
    }
      else
      {
        this.deleteMessage(conversation.id, newMessage.messageId)
      }
    }
    else
    {
      this.updateGroupList();
    }
  }

  saveConversations() {
    const userId = this.api.userId;
    localStorage.setItem(`${this.api.userId}_allConversations`, JSON.stringify(this.app.allConversationsMessages));
  }
  saveGrouping() {
    if(this.newData != null && this.newData.length>0)
      {
        localStorage.setItem(`${this.api.userId}_allGrouping`, JSON.stringify(this.newData));
      }
  }


  deleteMessage(conversationId : string, messageId : string)
  {
    this.app.allConversationsMessages[conversationId].forEach(message => {
        if(message.id == messageId)
        {
        message.body = " This Message is deleted";
        message.fileDownloadUrl = null;
        message.type = 1;
        message.style = "deleted";
        }

    });
  }
  loadSavedGrouping() {
    const savedGrouping = localStorage.getItem(`${this.api.userId}_allGrouping`);
    if (savedGrouping !== null && savedGrouping.trim() !== '') {
      this.updateCards(JSON.parse(savedGrouping), true);
      this.inited = true;
      this.spinner.hide();
    }
  }

  loadSavedConversationsMessages() {
    const savedConversations = localStorage.getItem(`${this.api.userId}_allConversations`);
    if (savedConversations) {
      this.app.allConversationsMessages = JSON.parse(savedConversations);
    }
  }

  setBackGroundUpdateInterval()
  {
    setInterval(()=> {

    console.log("runs from setBackGroundUpdateInterval");
   this.updateGroupList();
    },6000000);
  }

  private setupUpdateGroupListTimer(): void {
    const tenMinutes = 2000000;
    const fortySeconds = 150000;

    const stopTimer$ = timer(tenMinutes);
    console.log("We are runnning from SetupUpdateGroupListTImer , whatsappstatus = ",this.app.isWhatsappConnected);
    timer(0, fortySeconds) 
        .pipe(takeUntil(stopTimer$))
        .subscribe({
            next: () => {
              console.log("runs from setupUpdateGroupListTimer");
                this.updateGroupList(true);
            },
            complete: () => {console.log('Update cycle completed after 100 minutes');
              this.app.conversationSyncMessage = false;
             }
        });
  }

  loadConversationMessages(conversationId : any, callback : any) {
    this.api.sendGetRequest({ action: 'Message/GetWhatsappConversationMessages', params: { contactId: conversationId, user: this.api.userId } })
      .subscribe((messages) => {
        this.app.allConversationsMessages[conversationId] = messages;
        callback();
      });
  }

  appendNewMessage(conversationId : any, newMessage : any, platform : string) {
    const convertedMessage = this.convertNewMessage(newMessage,platform);
    const isMessageExist = this.app.allConversationsMessages[conversationId] && this.app.allConversationsMessages[conversationId].some(conversation => conversation.id === convertedMessage.id);
    if (!isMessageExist) {
      if(convertedMessage.body == null && platform !="whatsapp" ) 
        {
          convertedMessage.body = "Has recibido un mensaje de audio. Los mensajes de audio no son compatibles por ahora.";             
          convertedMessage.style= "deleted"
        }
        console.log("hna" ,newMessage);
      if(this.app.allConversationsMessages[conversationId])
      {
        if (!this.app.allConversationsMessages[conversationId].some(existingMsg => existingMsg.id === convertedMessage.id)) {
          console.log('A message with this ID ADDED SUCCEFULLY',convertedMessage);

          this.app.allConversationsMessages[conversationId].push(convertedMessage);
        } else {
          console.log('A message with this ID already exists.');
        }
      }
      else {
        if(platform == "whatsapp") 
        {
          this.fetchMessagesForConversation(conversationId, 200).then(messagesResponse => {
            this.app.allConversationsMessages[conversationId] = messagesResponse;
          }).catch(error => {
            console.error('Error fetching messages:', error);
          });   
        }
        if(platform == "instagram")
        {
          this.fetchInstagramMessages(conversationId);
        }
        if(platform == "messenger")
        {
          this.fetchFacebookMessages(conversationId);
        }
      }
    } else {
        console.log('Duplicate message detected, not appending:', convertedMessage);
    }
  }

  ngOnDestroy(): void {
    this.viewportSubscription.unsubscribe();
  }

  async delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  toggleModoPrivado() {
    this.modoPrivadoActive = !this.modoPrivadoActive;
    this.app.handleBlur(this.modoPrivadoActive);
  }

  toggleNoLeidos() {
    this.noLeidosActive = !this.noLeidosActive;
    this.app.handleShowUnread(this.noLeidosActive);
  }

  private setViewPortSize(): void {
    this.viewportSubscription = this.viewportRuler.change().subscribe(() => {
      const { width, height } = this.viewportRuler.getViewportSize();
      this.setDialogWidth(width);
    });
    const { width, height } = this.viewportRuler.getViewportSize();
    this.setDialogWidth(width);
  }
  setDialogWidth(viewportWidth: number): void {
    const maxWidth = Math.min(viewportWidth * 0.9, 800); // Set maximum width to 90% of viewport or 800px
    this.dialogWidth = `${maxWidth}px`;
  }

  encryptString(key: string, plainText: string) {
    const encrypted = AES.encrypt(plainText, key);
    return encrypted.toString();
  }

  decryptString(key: string, encryptedText: string) {
    try {
      const combinedIvCt = enc.Base64.parse(encryptedText);
      const iv = enc.Hex.parse(combinedIvCt.toString().slice(0, 32));
      const cipherText = enc.Hex.parse(combinedIvCt.toString().slice(32));
      const decrypted = AES.decrypt(
        cipherText.toString(enc.Base64),
        enc.Utf8.parse(key),
        { iv: iv }
      );
      return decrypted.toString(enc.Utf8);
    } catch (error) {
      return false;
    }
  }
  checkStatus()
  {
    this.api.sendGetRequest({ action: 'Action/IsConnected' })
    .subscribe
    ({
      next: (res) => {
        this.app.isWhatsappConnected = res.whatsAppConnected;
        this.app.isFacebookConnected = res.facebookConnected;
        this.app.isInstagramConnected = res.instagramConnected;
        this.app.isWhatsappAPIConnected = res.whatsappApiConnected;
        console.log(res,this.app.isWhatsappAPIConnected,this.app.isInstagramConnected);
    }});
  }
  
/*
  checkIsConnected(isInit = true) {
    if (isInit) {
      this.inited = false;
    }

    this.api.sendGetRequest({ action: 'Action/IsConnected' })
      .subscribe({
        next: (res) => {
          this.app.isWhatsappConnected = res.whatsAppConnected;
          this.app.isFacebookConnected = res.facebookConnected;
          this.app.isInstagramConnected = res.instagramConnected;
          if (!this.app.isWhatsappConnected) this.whatsappConversationsIdsList = [];
          if (!this.app.isFacebookConnected) this.facebookConversationsIdsList = [];
          if (!this.app.isInstagramConnected) this.instagramConversationsIdsList = [];
          if (this.app.isWhatsappConnected || this.app.isFacebookConnected) {
            if (isInit) {
              if(this.app.isWhatsappConnected)
              this.loadSavedGrouping();
              // this.loadSavedConversationsMessages()
            }
            console.log("checkisConnected");
            this.setupUpdateGroupListTimer();
            } 
            else {
            this.spinner.hide();
            this.openConnectionModal(true);
          }
        },
        error: (error) => {
          console.error('Failed to check connection status:', error);
          this.spinner.hide();
          this.openConnectionModal(true);
        }
      });
       next: (res) => {
        this.app.isWhatsappConnected = res.whatsAppConnected;
        this.app.isFacebookConnected = res.facebookConnected;
        this.app.isInstagramConnected = res.instagramConnected;
  }
*/
  checkIsConnected(isInit = true) {
    if (isInit) {
      this.inited = false;
    }
    this.api.sendGetRequest({ action: 'Action/IsConnected' })
      .subscribe({
        next: (res) => {
          this.app.isWhatsappConnected = res.whatsAppConnected;
          this.app.isFacebookConnected = res.facebookConnected;
          this.app.isInstagramConnected = res.instagramConnected;
          this.app.isWhatsappAPIConnected = res.whatsappApiConnected;
          if(this.app.isWhatsappAPIConnected) 
            {
              this.app.botId = res.botId;
              this.app.isManual = res.manual;
          console.log(this.app.botId);
            }
          if(this.app.isWhatsappConnected || this.app.isFacebookConnected || this.app.isWhatsappAPIConnected)
          this.updateGroupList(true);
          else 
          {
            this.spinner.hide();
            this.openConnectionModal(true);
          }
        },
        error: (error) => {
          console.error('Failed to check connection status:', error);
          this.spinner.hide();
          this.openConnectionModal(true);
        }
      });
    }


  openConnectionModal(init : boolean = false) {
    if(init)
    {
      const dialogRef = this.dialog.open(InitConnectionModalComponent, {
        disableClose: true, 
      });
        dialogRef.afterClosed().subscribe((result) => {
          this.spinner.show();
          setTimeout(() => {
            this.checkIsConnected();
          }, 5000);
      });
    }
    else
    {
      const dialogRef = this.dialog.open(ConnectionModalComponent, {
        width: '750px',
        panelClass: 'custom-connection-modal-container'
      });
      this.app.connectionIsUpdated.subscribe(() => {
        location.reload();
      });
    }
  }
  
  private getCardScroll()
  {
    const listEls = document.querySelectorAll('.card-body');
    for (let i = 0; i < listEls.length; i++) {
      this.cardScrollPosition[i] = listEls[i].scrollTop;
    }
    
  }

  private setCardScroll(): void {
    const cardBodyElements = document.querySelectorAll('.card-body');
    for (let i = 0; i < cardBodyElements.length; i++) {
    if(this.cardScrollPosition[i]!=undefined && this.cardScrollPosition[i]>0)
      cardBodyElements[i].scrollTop = this.cardScrollPosition[i];
    }
  }

  disconnect() {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'Cerrar sesión',
          message: 'Estás seguro de que deseas desconectar tu cuenta?',
        },
        width: '400px',
        height: '200px',
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result == true) {
          this.api
            .sendGetRequest({ action: 'Action/Close' })
            .subscribe((res) => {
              this.app.isWhatsappConnected = false;
              console.log("from close call");
              this.updateGroupList(true);
              this.inited = false;
              this.spinner.show();
              this.checkIsConnected();
            });
        }
      });
  }
  
  helpModal() {
    const dialogRef = this.dialog.open(HelpLogoComponent, {
      data: {
        title: 'Help',
        message: '',
      },
      panelClass:'custom-helppopup',
      width:'60%'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  drag = (event: CdkDragStart<string[]>)  => {
    this.app.allowGroupUpdate = false;
  };

  drop = (event: CdkDragDrop<string[]>) => {

    this.app.allowGroupUpdate = true;
    this.dragByThisDevice = true;
    const current_card: any = this.app.cards.find(
      (card) => card?.grouping.id === (event.container.data as any)
    );
    const previous_card: any = this.app.cards.find(
      (card) => card?.grouping.id === (event.previousContainer.data as any)
    );
    if (event.previousContainer === event.container) {
      moveItemInArray(
        current_card['conversations'],
        event.previousIndex,
        event.currentIndex
      );
    } else {
      const id = previous_card['conversations'][event.previousIndex]['id'];
      const exist = current_card['conversations'].findIndex(
        (conv: any) => conv.id === id
      );
      if (exist === -1) {
        transferArrayItem(
          previous_card['conversations'],
          current_card['conversations'],
          event.previousIndex,
          event.currentIndex
        );
        this.api
          .sendPostRequest({
            action: 'Grouping/setGrouping',
            params: {
              groupingId: current_card.grouping.id,
              phone: id,
            },
          })
          .subscribe((res) => {
          });
      }
      this.leadUpdateService.notifyLeadUpdated();
      this.saveGrouping();
    }
  };

  createBox = (group: any) => {
    this.app.allowGroupUpdate = false;
    const ref = this.dialog.open(CreateGroupComponent);
    ref.afterClosed().subscribe((res) => {
      if (!res) {
        this.app.allowGroupUpdate = true;
        return;
      }
      this.api
        .sendPostRequest({
          action: 'Grouping/create',
          params: {
            name: res.name,
            color: res.color,
            emoji: res.emoji,
            position: group.position + 1,
          },
        })
        .subscribe((res) => {
          var afterIndex = this.app.cards.findIndex((item)=>item.grouping.id == group.id);
          this.app.cards.splice((afterIndex+1),0,{"grouping":res,"conversations":[]});
          this.sortAndUpdateCards();
          this.app.allowGroupUpdate = true;
        });
    });
  };
  
  connectionUpdated = () => {
    this.spinner.show();
    this.checkIsConnected(false);
  }
  async reloadSession(): Promise<any> {
    return new Promise((resolve, reject) => {
        this.api.sendGetRequest({action: 'Action/Reload'}).subscribe({
            next: (res) => {
                console.log(res);
                resolve(res);  // Resolve the promise with the response
            },
            error: (err) => {
                console.error(err);
                reject(err);  // Reject the promise if there's an error
            }
        });
    });
  }
  
  async getQr(): Promise<any> {
    return new Promise((resolve, reject) => {
        this.api.sendGetRequest({action: 'Action/GetQr'}).subscribe({
            next: (res) => {
                console.log(res);
                resolve(res);  // Resolve the promise with the response
            },
            error: (err) => {
                console.error(err);
                reject(err);  // Reject the promise if there's an error
            }
        });
    });
  }

  updateGroupList = (isInit = false) => {
    if(this.app.allowGroupUpdate && (this.app.isWhatsappConnected || this.app.isFacebookConnected || this.app.isWhatsappAPIConnected))
    {

      this.checkStatus();
      this.lastGetConversationsTime = Date.now();
      /*if(!this.app.isWhatsappConnected && !this.app.isFacebookConnected)
        {
        window.location.reload();
      }*/

      this.api
        .sendGetRequest({
          action: 'Message/GetConversations',
          params: { whatsappConnected: this.app.isWhatsappConnected, 
                    facebookConnected: this.app.isFacebookConnected,
                    instagramConnceted: this.app.isInstagramConnected,
                    whatsappApiConnected: this.app.isWhatsappAPIConnected
          },
        })

        .subscribe((res) => {
          if (this.app.allowGroupUpdate && res != null && res != "")
          {
            console.log(res);
            this.app.isManual=true;
            this.getCardScroll();
            let leadsCount=0   
            let amountCount=0
            let profitCount=0;
            let facebookIds : any = [];
            let instagramIds : any = [];
            let whatsappApiIds: any = [];
            let whatsappIds: { id: string, lastUpdate: string }[] = [];
            res.forEach((group:any) => {
              group.conversations.forEach((conversation:any) => {
                if(this.app.allRequestCount[conversation.id] == null)
                this.app.allRequestCount[conversation.id] = 0;
                if(conversation.conversationType == "whatsappApi")
                {
                  whatsappApiIds.push(conversation.id);
                }

                if(conversation.conversationType == "messenger")
                {
                  conversation.lastUpdate = this.convertUtcStringToLocal(conversation.lastUpdate); 
                  facebookIds.push(conversation.id)
                  if (this.facebookConversationsIdsList != null && conversation.id != null && !this.facebookConversationsIdsList.some((item:any) => item == conversation.id)
                    && this.conversationsCallCount > 1)                                   
                  {
                    conversation.unreadMessages = 1;
                  }
                }
                if(conversation.conversationType=="instagram")
                {
                  conversation.lastUpdate = this.convertUtcStringToLocal(conversation.lastUpdate); 
                  instagramIds.push(conversation.id);
                  if (this.instagramConversationsIdsList != null && conversation.id != null && !this.instagramConversationsIdsList.some((item:any) => item == conversation.id)
                    && this.conversationsCallCount > 1)                                   
                  {
                    conversation.unreadMessages = 1;
                  }
                }
                if(conversation.conversationType=="whatsapp")
                {
                  if(conversation.id!=null) 
                  whatsappIds.push({ id: conversation.id, lastUpdate: conversation.lastUpdate });
                }
                const cachedData = this.recentMessagesCache.get(conversation.id);
                if (cachedData && cachedData.timestamp > this.lastGetConversationsTime) {
                    conversation.amount = cachedData.amount;
                    conversation.lastMessage = cachedData.message;
                    conversation.unreadMessages = cachedData.unreadCount;
                    console.log(cachedData,conversation.amount,"homa");
                }

                if(conversation.conversationType != "whatsapp")
                {
                  if(cachedData)
                    {
                      console.log("kayna",conversation.unreadMessages,cachedData.unreadCount)
                      conversation.unreadMessages = cachedData.unreadCount;
                    }
                }
                if(conversation.id!=null) 
                  {
                    leadsCount += 1;
                    if(this.app.ConversationUploadingVideo[conversation.id] == null)
                    this.app.ConversationUploadingVideo[conversation.id] = false;
                  }
                amountCount += conversation.amount;
                if(conversation.won)
                profitCount += conversation.amount;
              });
            });
            whatsappIds.sort((a: { id: string, lastUpdate: string }, b: { id: string, lastUpdate: string }) => new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime());
            facebookIds.sort((a: { id: string, lastUpdate: string }, b: { id: string, lastUpdate: string }) => new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime());
            instagramIds.sort((a: { id: string, lastUpdate: string }, b: { id: string, lastUpdate: string }) => new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime());
            this.whatsappApiConversationsIdsList = whatsappApiIds;
            this.facebookConversationsIdsList = facebookIds;
            this.instagramConversationsIdsList = instagramIds;
            this.whatsappConversationsIdsList = whatsappIds;
            this.app.totalLeads = leadsCount;
            this.app.totalAmount = amountCount;
            this.app.totalProfit = profitCount;
            this.groupList = [];
            this.updateCards(res,true);
            this.saveGrouping();
            this.app.modalCloseEvent.emit();
            this.spinner.hide();
            if(isInit)
              {
                this.inited = true;
                this.spinner.hide();
                if(this.app.isWhatsappAPIConnected)
                  this.getWhatsappApiConversationsMessages();
                if(this.app.isInstagramConnected)
                  this.getInstagramConversationsMessages();
                if (this.app.isWhatsappConnected) {
                  this.getWhatsappConversationsMessages(200).then(() => {
                    return this.getWhatsappConversationsMessages(200);
                  })
                }
                if(this.app.isFacebookConnected)
                this.getFacebookConversationsMessages();
              }
            this.initialLoad = true;
            this.conversationsCallCount++;
          }
          if(isInit)
          {
            this.inited = true;
            this.spinner.hide();
          }

      },
        (error) => {
          window.location.reload();
        });
    }
  };

  async getWhatsappConversationsMessages(count: number): Promise<void> {
    // Iterate over the sorted list of WhatsApp conversation IDs
    for (const conversation of this.whatsappConversationsIdsList) {
        const conversationId = conversation.id;
        try {
            this.app.allRequestCount[conversationId]++;
            if(this.app.allRequestCount[conversationId] && this.app.allRequestCount[conversationId]<=3 && !this.app.ConversationUploadingVideo[conversationId] && !this.app.conversationFullyLoaded[conversationId])
            {
            const messagesResponse = await this.fetchMessagesForConversation(conversationId, count);
            if(messagesResponse != null)
            {
            for (let i = 0; i <  messagesResponse.length; i++) {
              if ( messagesResponse[i]['body'])
                messagesResponse[i]['body'] = this.app.wrapLinksWithAnchorTag( messagesResponse[i]['body']);
            }
            if(messagesResponse.length == 200 ) this.app.conversationFullyLoaded[conversationId] = true;
            }
            const newMessages = messagesResponse || [];
            let existingMessages = this.app.allConversationsMessages[conversationId] || [];

            // Get last 5 messages from existing and new messages for comparison
            const lastExistingMessages = existingMessages.slice(-5);
            const lastNewMessages = newMessages.slice(-5);

            // Compare and merge messages
            newMessages.forEach((newMsg:any) => {
                const existingMatchIndex = lastExistingMessages.findIndex(existingMsg => 
                    existingMsg.body === newMsg.body &&
                    existingMsg.fromMe === newMsg.fromMe
                );

                if (existingMatchIndex === -1) {
                    existingMessages.push(newMsg);
                } else {
                    // Update existing message properties
                    const fullIndex = existingMessages.length - 5 + existingMatchIndex; // Adjust for full index
                    existingMessages[fullIndex] = {...existingMessages[fullIndex], ...newMsg};
                }
            });

            existingMessages.sort((a, b) => a.timestamp - b.timestamp);
            this.app.allConversationsMessages[conversationId] = messagesResponse;
            this.cdr.detectChanges();
            if (this.app.allRequestCount[conversationId] >= 1) {
                this.app.conversationFullyLoaded[conversationId] = true;
            }
          }

        } catch (error) {
            console.error(`Error fetching messages for contact ${conversationId}:`, error);
        }
    }
}

  moveConversation(newGroupId: string, conversationId: string) {
    let sourceGroup = this.findGroupContainingConversation(conversationId);
    let targetGroup = this.app.cards.find(group => group.grouping.id === newGroupId);

    if (sourceGroup && targetGroup) {
      const conversationIndex = sourceGroup.conversations.findIndex((c:any) => c.id === conversationId);
      if (conversationIndex !== -1) {
        // Remove from current group
        const [movedConversation] = sourceGroup.conversations.splice(conversationIndex, 1);
        // Add to target group
        targetGroup.conversations.push(movedConversation);
        // Sort or process as needed, for example:
        this.sortConversationsInCard(targetGroup.grouping.id);
        // Trigger Angular's change detection manually if necessary
        this.cdr.detectChanges();
      }
    }
  }

  findGroupContainingConversation(conversationId: string) {
    return this.app.cards.find(group => group.conversations.some((c:any) => c.id === conversationId));
  }

  async fetchMessagesForConversation(conversationId: string, count: number): Promise<any[]> {
    return new Promise((resolve, reject) => {
        this.api.sendGetRequest({
            action: 'Message/GetChatMessages',
            params: { contactNumber: conversationId, limit: count }
        }).subscribe(
            response => resolve(response),
            error => reject(error)
        );
    });
  }
  configurateWhatsappApi() {
    this.dialog.open(WhatsappApiConfigComponent, {
      width: '600'    });
  }
  getWhatsappApiConversationsMessages = ()  =>
    {
      this.whatsappApiConversationsIdsList.forEach((conversationId:any) => {
          this.api.sendGetRequest({ action: 'Message/GetLeadMessages', params: { leadId: conversationId} })
          .subscribe((response) => {
            response.forEach((message:any) => {
              message.timestamp = this.convertLocal(message.timestamp);
              message.style = "default";
            });
            this.app.allConversationsMessages[conversationId] = response;
            this.app.conversationInitiallyLoaded[conversationId] = true;
            this.app.conversationFullyLoaded[conversationId]= true;
          });
      });
    }
  getFacebookConversationsMessages = ()  =>
  {
    this.facebookConversationsIdsList.forEach((conversationId:any) => {
      if(this.app.allConversationsMessages[conversationId] == null || this.app.allConversationsMessages[conversationId].length<=0)
      {
        this.api.sendGetRequest({ action: 'Message/GetFacebookConversationMessages', params: { conversationId: conversationId} })
        .subscribe((response) => {
          response.messages.forEach((message:any) => {
            message.timestamp = this.convertLocal(message.timestamp);
            message.style = "default";
          });
          
          this.app.allConversationsMessages[conversationId] = response.messages;
          this.app.allConversationsFetchToken[conversationId] = response.nextFetch;
          this.app.conversationInitiallyLoaded[conversationId] = true;
          if(response.nextFetch == "")
          this.app.conversationFullyLoaded[conversationId]= true;
        });
      }
    });
  }

  getInstagramConversationsMessages = ()  =>
  {
    this.instagramConversationsIdsList.forEach((conversationId:any) => {
      if(this.app.allConversationsMessages[conversationId] == null || this.app.allConversationsMessages[conversationId].length<=0)
      {
        this.api.sendGetRequest({ action: 'Message/GetInstagramConversationMessages', params: { conversationId: conversationId} })
          .subscribe((response) => {
          response.messages.forEach((message:any) => {
          message.timestamp = this.convertLocal(message.timestamp);
          if(message.type == 5 && (message.fileDownloadUrl == null || message.fileDownloadUrl == "") )
          {
            message.body = "Los mensajes de audio antiguos no son compatibles en este momento."; 
            message.style= "deleted"
            message.type = 1;
          }
        });
        this.app.allConversationsMessages[conversationId] = response.messages;
        this.app.allConversationsFetchToken[conversationId] = response.nextFetch;
        this.app.conversationInitiallyLoaded[conversationId] = true;
        if(response.nextFetch == "")
        this.app.conversationFullyLoaded[conversationId]= true;
      });
      }
    });
  }

  fetchInstagramMessages = (conversationId:string) =>
  {
      this.api.sendGetRequest({ action: 'Message/GetInstagramConversationMessages', params: { conversationId: conversationId} })
          .subscribe((response) => {
          response.messages.forEach((message:any) => {
          message.timestamp = this.convertLocal(message.timestamp);
          if(message.type == 5 && (message.fileDownloadUrl == null || message.fileDownloadUrl == "") )
          {
            message.body = "Los mensajes de audio antiguos no son compatibles en este momento."; 
            message.style= "deleted"
            message.type = 1;
          }
        });
        this.app.allConversationsMessages[conversationId] = response.messages;
        this.app.allConversationsFetchToken[conversationId] = response.nextFetch;
        this.app.conversationInitiallyLoaded[conversationId] = true;
        if(response.nextFetch == "")
          this.app.conversationFullyLoaded[conversationId]= true;
      });
  }

  fetchFacebookMessages = (conversationId:string) =>
  {
      this.api.sendGetRequest({ action: 'Message/GetFacebookConversationMessages', params: { conversationId: conversationId} })
      .subscribe((response) => {
        response.messages.forEach((message:any) => {
        message.timestamp = this.convertLocal(message.timestamp);
        message.style = "default";
      });
      this.app.allConversationsMessages[conversationId] = response.messages;
      this.app.allConversationsFetchToken[conversationId] = response.nextFetch;
      this.app.conversationInitiallyLoaded[conversationId] = true;
      if(response.nextFetch == "")
      this.app.conversationFullyLoaded[conversationId]= true;
      });
  }
    
  showMainView = (contactId: number, card: any, conversationId: any) => {
    this.app.showCheck;
    this.currentContactId = contactId;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = '90vh';
    dialogConfig.width = '90%';
    dialogConfig.maxWidth = '1300px';
    dialogConfig.panelClass='custom-messagebox';
    dialogConfig.data = {
      contactId: contactId,
      groupList: this.groupList,
      defaultGroupId: card,
      conversationId: conversationId
    };
    const ref: MatDialogRef<MainViewComponent> = this.dialog.open(
      MainViewComponent,
      dialogConfig
    );
  };

updatePosition = (movement: number, grouping: any) => {

    const currentIndex = this.app.cards.findIndex((item: any) => item.grouping.id === grouping.id);
    if (currentIndex !== -1) {
      const newIndex = currentIndex + movement;

      if (newIndex >= 1 && currentIndex >= 1  && newIndex < this.app.cards.length) {
        // Swap positions
        [this.app.cards[currentIndex].grouping.position, this.app.cards[newIndex].grouping.position] = [
          this.app.cards[newIndex].grouping.position,
          this.app.cards[currentIndex].grouping.position,
        ];

        this.sortAndUpdateCards();
        const movementAction = movement > 0 ? 'increaseGroupingPosition' : 'decreaseGroupingPosition';
        this.api
          .sendPostRequest({
            action: `Grouping/${movementAction}`,
            params: { groupingId: grouping.id },
          })
          .subscribe();
      }
    }
    this.saveGrouping();
  };

  private sortAndUpdateCards() {
    this.app.cards = this.app.cards.sort((p: any, n: any) => p.grouping.position - n.grouping.position);
    this.app.cards.forEach((card, index) => (card.grouping.position = index+1));
  }


  updateCards(data?: any,isInit:boolean=false) {
    if(!this.app.allowGroupUpdate){
      return;
    }
    if (data) {
      const filteredData = data.map((card:CardType) => ({
        ...card,
        conversations: card.conversations.filter((conv:any) => conv.id !== null)
    }));

      this.newData = filteredData.sort(
        (p: any, n: any) => p.grouping.position - n.grouping.position
      );
      if(isInit){
        this.app.cards = this.newData;
      }
    }
    for (let g = 0; g < this.app.cards.length; g++) {
      var groupName = this.app.cards[g]?.grouping?.name;
      var id = this.app.cards[g]?.grouping?.id;
      this.groupList.push({ id: id, groupName: groupName });
    }
    var that = this;
    setTimeout(function(){
      that.setCardScroll();
    },0)
  }

  changeConversationLastMessage()
  {
    var conversation = this.findConversationById("ss");
  }

  handleSearch(e: any) {
    this.search = e;
    this.updateCards();
  }

  setupInactivityTimer() {
    this.resetInactivityTimer();
    document.addEventListener('mousemove', this.resetInactivityTimer.bind(this));
    document.addEventListener('click', this.resetInactivityTimer.bind(this));
    document.addEventListener('scroll', this.resetInactivityTimer.bind(this));
    document.addEventListener('keypress', this.resetInactivityTimer.bind(this));
  }
  
  resetInactivityTimer() {
    clearTimeout(this.inactivityTimer);
    this.isUserInactive = false;
    this.inactivityTimer = setTimeout(() => {
      this.isUserInactive = true;
    }, this.inactivityDuration);
  }

  convertUtcToLocal(utcTimestamp: number): Date {
    const utcDate = new Date(utcTimestamp);
    const localDate = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
    );
    return localDate;
  }
  
  convertLocal(utcTimestamp: number): Date {
    const utcDate = new Date(utcTimestamp);
    const localDate = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
    );
    return localDate;
  }
  convertUtcStringToLocal(utcDateString: string): Date {
    const utcDate = new Date(utcDateString);
    const localDate = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
    );
    return localDate;
  }
  
  convertNewMessage(newMessage: any, platform: string): any {
    let convertedMessage: any;

    if(platform == "whatsapp")
    {
      convertedMessage = {
        timestamp:this.convertLocal(newMessage.t*1000),
        id: newMessage.id,
        body: newMessage.body,
        caption: newMessage.caption,
        fromMe: newMessage.fromMe,
        type: newMessage.type,
        fileName: newMessage.filename,
        fileDownloadUrl: newMessage.fileDownloadUrl,
        description: newMessage.description,
        hasReaction: newMessage.hasReaction,
        isForwarded: newMessage.isForwarded,
        duration: newMessage.duration,
        quotedMessageId: newMessage.quotedMessageId
      };
    }
    else if(platform == "instagram" || platform == "messenger")
    {
      convertedMessage = {
        timestamp: this.convertLocal(newMessage.timestamp),
        id: newMessage.messageId,
        body: newMessage.message,
        fromMe: newMessage.fromMe,
        type: newMessage.messageType,
        fileDownloadUrl: newMessage.fileDownloadUrl
      };
    }
  
 
    return convertedMessage;
  }  
  
  getTypeFromNewMessage(type : string) : any{
    if(type=='chat') return 1;
    if(type=='image') return 2;
    if(type=='video') return 3;
    if(type=='document') return 4;
    if(type=='ptt') return 5;
    if(type=='audio') return 5;
  }

  findConversationById(conversationId: string) {
    for (const card of this.app.cards) {
      for (const conversation of card.conversations) {
        if (conversation.id === conversationId) {
          return conversation;
        }
      }
    }
    return null;
  }

  findConversationByParticipantId(participantId: string) {
    for (const card of this.app.cards) {
      for (const conversation of card.conversations) {
        if (conversation.participantId === participantId) {
          return conversation;
        }
      }
    }
    return null;
  }

  findGroupingById(conversationId: string) {
    // Iterate over each card in app.cards
    for (const card of this.app.cards) {
        // Check if this card has the conversation with the given conversationId
        const foundConversation = card.conversations.find((conversation:any) => conversation.id === conversationId);

        // If found, return the grouping ID
        if (foundConversation) {
            return card.grouping.id;
        }
    }

    // If no matching conversation is found, return null
    return null;
  }
  sortConversationsInCard(cardId: string) {
    const card = this.app.cards.find((card : any) => card.grouping.id === cardId);
    if (card) {
        card.conversations.sort((a:any, b:any) => new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime());
    }

  }
  findMostRecentUpdateInCard(card: any): number {
    let mostRecentUpdate = new Date(0); // Start with a default old date
    card.conversations.forEach((conversation: any) => {
      if (conversation.lastUpdate && new Date(conversation.lastUpdate) > mostRecentUpdate) {
        mostRecentUpdate = new Date(conversation.lastUpdate);
      }
    });
    return mostRecentUpdate.getTime(); // Return the timestamp
  }
  
}