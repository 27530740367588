import { Component, OnInit, EventEmitter, Output} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  MatDialog,
  MatDialogConfig,
} from '@angular/material/dialog';
import { AppService } from '../../app.service';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-connection-modal',
  templateUrl: './connection-modal.component.html',
  styleUrls: ['./connection-modal.component.scss']
})
export class ConnectionModalComponent implements OnInit {
  showWhatsappAccordion = false;
  showInstagramAccordion = false;
  instaIsLoading = false;
  qrdata: string = '';
  pages : any;
  selectedPageId: string = "";
  userId : string = "";
  @Output() serviceConnected = new EventEmitter<void>();

  constructor(
    private dialogRef: MatDialogRef<ConnectionModalComponent>,
    private dialog: MatDialog,
    public app: AppService,
    public api: ApiService){}
  
    ngOnInit(): void {
      this.userId = this.api.userId;
      if (this.app.isFacebookConnected) {
        this.getUserPages();
      }
      this.app.modalCloseEvent.subscribe(() => {
        this.onConfirm();
      });
      console.log("test,",this.app.isWhatsappConnected);
    }
    

    toggleWhatsAppConnection() {
      if(!this.app.isWhatsappConnected)
      {
      this.showWhatsappAccordion = !this.showWhatsappAccordion;
        if (this.showWhatsappAccordion) {
        this.getQRCode();
        }
      }
      else
      {
        this.app.closeSession();
        
      }
    }
    toggleWhatsAppApiConnection() {

      if(this.app.isWhatsappAPIConnected)
      {
        this.api.sendPostRequest({action: 'Action/LogoutApi', params: {}})
      .subscribe((res: any) => {
        window.location.reload();
      });
      }
      else
      {
        this.api.sendPostRequest({
          action: 'Action/CreateBotAPI',
        }).subscribe({
          next: (res) => {
            if (res && !res.redirect) {
              this.app.isWhatsappAPIConnected = true;
            } else if (res && res.redirect && res.url) {
              window.open(res.url, '_blank');
            }
          },
          error: (err) => {
            console.error('Error connecting to WhatsApp API:', err);
          }
        });
      }
    }
  getUserPages() {
    this.api.sendGetRequest({action: 'Action/GetUserPages', params: {}})
      .subscribe((res: any) => {
        this.pages = res;
        if (this.pages && this.pages.length > 0) {
          this.onPageChangeHandler();          
        }
      });
  }

  onPageChange(event: any) {
    const isInitial = !!event.pageId;
    this.selectedPageId  = event.value ? event.value : event.pageId;
    this.api
      .sendPostRequest({
        action: 'Action/SaveFacebookPage',
        
        params: { pageId: this.selectedPageId
                },
      }).subscribe((res) => {
        if(!isInitial)
        this.app.connectionIsUpdated.emit();
      });     
  }

  onPageChangeHandler() {
    this.api.sendGetRequest({ action: 'Action/GetSelectedPage' }).subscribe(
      (res: any) => {
        console.log(res);
        this.selectedPageId = res;
        this.showInstagramAccordion = true;
        this.onPageChange({pageId: this.selectedPageId});
      },
      error => {
        console.error('Error fetching selected page:', error);
        // Handle the error appropriately
      }
    );
  }
  
  getQRCode() {
    this.api.sendGetRequest({ action: 'Action/GetQrCode' }).subscribe(
      res => {
        if (res) {
          this.qrdata = 'data:image/png;base64,' + res;
          this.CheckSession();
        } else {
          this.app.checkIsConnectedEvent.emit();
        }
      },
      error => {
        console.error('Error fetching QR code:', error);
        this.getQRCode();
      }
    );
  }

  CheckSession(isInit = true) {
    this.api.sendGetRequest({ action: 'Action/SessionCheckAndCreate' })
      .subscribe({
        next: (session) => {
          console.log("session",session);
          if(session.status=="WORKING")
          {
  
            this.app.isWhatsappConnected = true;
            this.showWhatsappAccordion = false;
            localStorage.clear();
            window.location.reload();
          }
          else
          {
            this.CheckSession();
          }
        },
        error: (error) => {
          console.error('Failed to check connection status:', error);
        }
      });
    }
    
  changeQrCode()
  {
    this.qrdata = "";
    this.api.sendGetRequest({ action: 'Action/GetQrCode' }).subscribe(
      res => {
        if (res) {
          this.qrdata = 'data:image/png;base64,' + res;
        } 
      },
      error => {
        console.error('Error fetching QR code:', error);
        window.location.reload();
      }
    );
  }
  /*SetGetQrCodeInterval() {
    const intervalId = setInterval(() => {
        this.api.sendGetRequest({ action: 'Action/GetQr' }).subscribe(
            res => {
                if (res) {
                    this.qrdata = 'data:image/png;base64,' + res;
                } else {
                    console.error('No QR code received');
                }
            },
            error => {
                console.error('Error fetching QR code:', error);
            }
        );

        // Check if the condition to stop fetching QR code is met
        if (this.app.isWhatsappConnected) {
            clearInterval(intervalId);
            console.log('QR Code fetching stopped: WhatsApp is connected.');
        }
    }, 2000);
}*/
  waitForWhatsAppConnection() {
    this.api.sendGetRequest({ action: 'Action/WaitUntilConnected' }).subscribe(
      res => {
        if (res === 'true') {
          this.app.isWhatsappConnected = true;
          this.showWhatsappAccordion = false;
          this.app.connectionIsUpdated.emit();   
        }
      },
      error => {
        // Handle the error from the WaitUntilConnected API request
        console.error('Error waiting for WhatsApp connection:', error);
        this.showWhatsappAccordion = false;
      }
    );
  }
  
  handleQRCodeError() {
    this.showWhatsappAccordion = false;
  }

  toggleInstagramConnection() {
    if(!this.app.isFacebookConnected) {
        this.api.sendGetRequest({action: 'Action/GetFacebookLoginUrl', params: {}})
        .subscribe(
            (res: any) => {
              this.app.initiatedFromConnectionModal = true;
              const loginWindow = window.open(res.url, '_blank');
              this.instaIsLoading = true;
              const intervalId = setInterval(() => {
                if (loginWindow && loginWindow.closed) {
                  clearInterval(intervalId);
                  this.instaIsLoading = false;
                }
              }, 1000);
            }
        ); 
    }
    else
    {
      this.app.facebookLogout();
    }
  }
  
  onConfirm() {
    this.dialogRef.close();
  }
  
  //close dialog of chat popup
  closeModal(){
    this.dialogRef.close();
  }
}
