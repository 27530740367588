<form (ngSubmit)="updateBotAPI()">
    <mat-form-field appearance="fill">
      <mat-label>Business Name</mat-label>
      <input matInput placeholder="Enter your business name" [(ngModel)]="form.name" name="name">
    </mat-form-field>
    <mat-form-field appearance="fill" class="mt-2">
      <mat-label>Instructions</mat-label>
      <textarea matInput placeholder="Enter instructions" [(ngModel)]="form.instructions" name="instructions" rows="5"></textarea>
    </mat-form-field>
    <div class="checkbox-manual">
      <mat-checkbox [(ngModel)]="form.manual" name="manual">Manual</mat-checkbox>
    </div>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button color="primary" type="submit">Save</button>
    </mat-dialog-actions>
  </form>