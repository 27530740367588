<div class="card" [style.background-color]="color">
    <div class="action-group2">
      <span class="group-amount-label">{{ this.conversations.length }} leads</span>
      <span class="group-amount-label">Hay {{ card.grouping.groupAmount | number : '1.0-0' }} {{app.selectedSymbol}}</span>
    </div>
    <div class="card-header">
        <ng-container *ngIf="isEditTitle">
            <input type="text" [(ngModel)]="title" class="card-title" />
            <div class="action-group">
                <div class="action-edit" (click)="saveTitle()">
                    <svg class="material-icons" viewBox="0 0 16 16" height="0.8em" width="0.8em">
            <path
              d="M10.97 4.97a.75.75 0 011.07 1.05l-3.99 4.99a.75.75 0 01-1.08.02L4.324 8.384a.75.75 0 111.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 01.02-.022z"
            />
          </svg>
                </div>
                <div class="action-edit action-cancel" (click)="cancelEditTitle()">
                    <svg class="material-icons" viewBox="0 0 470 1000" height="0.8em" width="0.8em">
            <path
              d="M452 656c12 12 18 26.333 18 43s-6 31-18 43c-12 10.667-26.333 16-43 16s-31-5.333-43-16L234 590 102 742c-12 10.667-26.333 16-43 16s-31-5.333-43-16C5.333 730 0 715.667 0 699s5.333-31 16-43l138-156L16 342C5.333 330 0 315.667 0 299s5.333-31 16-43c12-10.667 26.333-16 43-16s31 5.333 43 16l132 152 132-152c12-10.667 26.333-16 43-16s31 5.333 43 16c12 12 18 26.333 18 43s-6 31-18 43L314 500l138 156"
            />
          </svg>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="!isEditTitle">
            <mat-icon class="lock-icon" *ngIf="cardIndex=== 0">lock_outline</mat-icon>
            <span class="card-title">{{ card.grouping.name }}</span>
            <div class="action-group">
                <div class="action-edit" (click)="editTitle()">
                    <svg class="material-icons" viewBox="0 0 16 16" height="0.8em" width="0.8em">
            <path
              d="M12.146.146a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-10 10a.5.5 0 01-.168.11l-5 2a.5.5 0 01-.65-.65l2-5a.5.5 0 01.11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 01.5.5v.5h.5a.5.5 0 01.5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 015 12.5V12h-.5a.5.5 0 01-.5-.5V11h-.5a.5.5 0 01-.468-.325z"
            />
          </svg>
                </div>
                <div class="action-edit action-delete" (click)="deleteGroup()" *ngIf="conversations.length === 0">
                    <mat-icon>delete</mat-icon>
                </div>
            </div>
        </ng-container>
        <div class="card-plus" (click)="createBox(card.grouping)">
            <svg class="card-plus-icon" viewBox="0 0 1024 1024" fill="currentColor">
        <path
          d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"
        />
      </svg>
        </div>
    </div>
    <div class="action-group1" *ngIf="isEditTitle && cardIndex > 0">
      <span class="btn" [ngClass]="{ disabled: card.grouping.position === 0 || card.grouping.position === 1 }" (click)="updatePosition(-1, card.grouping)">
        <i class="list-item__icon isax isax-arrow-left-14"></i>
      </span>
      <input type="color" [(ngModel)]="color" (change)="updateColor()" />
      <span class="btn" [ngClass]="{ disabled: card.grouping.position === columnLength - 1 || card.grouping.position === 0  }" (click)="updatePosition(1, card.grouping)">
        <i class="list-item__icon isax isax-arrow-right-41"></i>
      </span>
    </div>
    <div class="card-body cart-scroll" cdkDropList [cdkDropListData]="card.grouping.id" (cdkDropListDropped)="dropFunction($event)">
        <ng-container *ngFor="let contact of conversations">
          <div>
            <div (cdkDragStarted)="dragFunction($event)" cdkDrag class="contact-item" [ngClass]="{ 'highlight-contact': contact.unreadMessages > 0 }" 
                  *ngIf="(app.showUnread && contact.unreadMessages > 0) || !app.showUnread"
                  (click)="showMainView(contact, card?.grouping?.id)">
              <div class="contact-avatar">
                    <span class="badge" *ngIf="contact.unreadMessages > 0">
                      {{contact.unreadMessages}}
                    </span>
                    <img [src]="contact.thumbnailUrl || './assets/guest.png'" />
                    <img [src]="contact.conversationType === 'whatsapp' ? 'assets/whatsapp-icon.svg' : contact.conversationType === 'instagram' ? 'assets/instagram-icon.svg' :
                                contact.conversationType === 'messenger' ? 'assets/messenger-icon.svg' : 'assets/busniess.png'" 
                    class="conversation-service-icon"/>
                  </div>
              <div class="contact-name">
                <span class="contact-name-text" [class.blurred]="app.showCheck" *ngIf="app.showCheck">
                  <span class="fi fi-{{ contact.countryCode | lowercase }}"></span>
                  {{ contact.name || contact.phoneFormatted }}
                </span>
                <span class="contact-name-text" *ngIf="!app.showCheck">
                  <span *ngIf="contact.conversationType=='whatsapp' || contact.conversationType=='whatsappApi'" class="fi fi-{{ contact.countryCode | lowercase }}"></span>
                  <img *ngIf="contact.conversationType=='instagram'" class="worldwide-icon" src="assets/worldwide-icon.png"/>
                  <span [ngClass]="{
                    'contact-name-label-whatsapp': contact.conversationType=='whatsapp',
                    'contact-name-label': contact.conversationType=='instagram'
                  }">{{ contact.name || contact.phoneFormatted }}</span>
                </span>                
                <span class="contact-last-message" 
                  *ngIf="contact.lastMessage != null">{{
                      (contact.lastMessage?.type === 1 || contact.lastMessage?.type === 0)
                      ? contact.lastMessage.body
                      : "Compartió " + typeLabel[contact.lastMessage?.type]}}
                </span>
              </div>
              <div class="emoji-container">
              <span class="emoji-icon" *ngIf="contact.emoji">{{contact.emoji}}</span>
              </div>
              <div class="contact-star">
                <span>
                  {{ contact.lastUpdate | convertDate }}
                </span>
              </div>
            </div>
          </div>
        </ng-container>
    </div>
    <div class="card-footer"></div>
</div>