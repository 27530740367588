import { Component, OnInit } from '@angular/core';
import { ApiService } from '../shared/api.service';
import { AppService } from '../app.service';

@Component({
  selector: 'app-whatsapp-api-config',
  templateUrl: './whatsapp-api-config.component.html',
  styleUrls: ['./whatsapp-api-config.component.scss']
})
export class WhatsappApiConfigComponent implements OnInit {
  form = {
    id: this.app.botId,
    name: '',
    instructions: '',
    manual: this.app.isManual
  };
  constructor(    private api: ApiService, private app: AppService
  ) { }

  ngOnInit(): void {
  }
  updateBotAPI() {
    this.api.sendPostRequest({
      action: 'Action/UpdateBotAPI', // Ensure this matches the endpoint expecting the POST
      body: this.form
    }).subscribe({
      next: (res) => {
        console.log('Update successful', res);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      error: (error) => {
        console.error('Failed to update', error);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  }
  

}
